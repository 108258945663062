import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from './index';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { AttachmentData } from '@/views/Payment/FlRt/types';
export const getBasicDetail = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/basic/apply/retailInfo', params);
};
export const getOperRecordDetail = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/basic/apply/operationDetails', params);
};
export const getPayountRoundByYearForSearch = (params = {}): Promise<any> => {
  //获取查询区点击“年”的payout Round数据
  return fetchGet('/ptapi/overview/findPayoutRoundByYear', params);
};
export const getInitSelectedDataForSearch = (params = {}): Promise<any> => {
  //获取初始化查询区数据
  return fetchGet('/ptapi/overview/initPayoutFormSelect', params);
};
export const getMBAFCTable = (params = {}): Promise<any> => {
  //获取MBAFC列表数据
  return fetchPost('/ptapi/overview/list', params);
};
export const getApprovealMainTable = (params = {}): Promise<any> => {
  //获取审批菜单主页面列表数据
  return fetchPost('/ptapi/overview/pendingList', params);
};

export const getApprovealPaymentTrackingTable = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/trackingList', params);
};
// 替换接口
export const getDealerTrackingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/dealerTrackingList', params);
};
// 替换接口
export const getFinanceTrackingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/financeTrackingList', params);
};
// 替换接口
export const getDealerPendingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/dealer/pendingList', params);
};
// 替换接口
export const getFinancePendingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/finance/pendingList', params);
};
// MBAFC pending list 替换接口
export const getFinanceAFCPendingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/finance/AFCPendingList', params);
};
// MBLC pending list 替换接口
export const getFinanceLCPendingList = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPost('/ptapi/overview/finance/LCPendingList', params);
};
export const getUploadFileData = (params = {}): Promise<AttachmentData[]> => {
  return fetchGet('/ptapi/fileList', params);
};
// 删除
export const batchDelete = (ids: number[]): Promise<any> => {
  return fetchPost('/ptapi/overview/batchDelete', ids);
};
// 提交
export const batchSubmit = (ids: number[]): Promise<any> => {
  return fetchPostPic('/ptapi/overview/batchSubmit', ids);
};
// 撤回
export const batchWidthDraw = (data: any[]): Promise<any> => {
  return fetchPostPic('/ptapi/overview/widthDraw', data);
};
export const getRetailHistory = (params = {}): Promise<any> => {
  return fetchGet('/rvapi//basic/apply/retailHistory', params);
};
export const getlicenseView = (params = {}): Promise<string> => {
  return fetchGet('/rvapi/basic/certificate/licenseView', params);
};
export const getInvioceView = (params = {}): Promise<string> => {
  return fetchGet('/rvapi/basic/certificate/invoiceView', params);
};
export const delAttachment = (params = {}): Promise<any> => {
  return fetchGetMess('/ptapi/overview/deleteFile', params);
};
export const getExportSearchResult = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/basic/apply/resultExport', params);
};
export const submitInvoiceFile = (invoiceConfig = {}, vinNo = '', params = {}): Promise<any> => {
  return fetchPost('/rvapi/basic/certificate/invoice/upload?vinNo=' + vinNo, params, invoiceConfig);
};
export const uploadAttachment = (invoiceConfig = {}, paymentId = '', params = {}): Promise<any> => {
  return fetchPostPic('/ptapi/overview/uploadFile?paymentId=' + paymentId, params, invoiceConfig);
};
export const submitLicenseFile = (licenseConfig = {}, paymentId = '', params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/uploadFile?paymentId=' + paymentId, params, licenseConfig);
};
//#region [Payment aproval折叠面板相关接口]
// 获取payoutRound下拉列表
export const fetchPaymentAprovalPendingList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/pendingList', params);
};
export const fetchPaymentAprovalTrackingList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/trackingList', params);
};

// 此处查询round 详情数据，分为两类  mgmt为第一类  controlling/payment tracking dealer payout overview 为第二类
// 根据传过来的payoutRound获取面板的详情(第一类)
export const getDealer2RoundDetail = (payoutRound: string): Promise<unknown> => {
  return fetchGet(`/ptapi/detail/dealerPayoutOverview/${payoutRound}`);
};
// 根据传过来的payoutRound获取面板的详情(第二类)
export const getDealer2RoundDetailForPayout = (payoutRound: string): Promise<unknown> => {
  return fetchGet(`/ptapi/detail/dealer2RoundDetail/${payoutRound}`);
};
// pending aprroval
export const getDealer2RoundDetailForPeding = (payoutRound: string): Promise<unknown> => {
  return fetchGet(`/ptapi/detail/dealerPayoutOverview/${payoutRound}`);
};
// 审批
export const fetchPaymentApprove = (params = {}): Promise<any> => {
  return fetchPostPic('/ptapi/overview/approve', params);
};
// 拒绝审批
export const fetchPaymentReject = (params = {}): Promise<any> => {
  return fetchPostPic('/ptapi/overview/reject', params);
};
//获取MBAFC和MBLC下的payoutRound下拉框
export const getApprovePayoutSelectedData = (params = {}): Promise<string> => {
  return fetchGet('/ptapi/overview/initSelectForApprove', params);
};
//获取MBAFC和MBLC审批的列表
export const getApprovalMBAFCandLCTable = (params = {}): Promise<any> => {
  //获取MBAFC和MBLC审批的列表数据
  return fetchPost('/ptapi/overview/overviewList', params);
};
// 替换接口
export const getAFCTrackingList = (params = {}): Promise<any> => {
  //获取MBAFC和MBLC审批的列表数据
  return fetchPost('/ptapi/overview/AFCTrackingList', params);
};
// 替换接口
export const getLCTrackingList = (params = {}): Promise<any> => {
  //获取MBAFC和MBLC审批的列表数据
  return fetchPost('/ptapi/overview/LCTrackingList', params);
};
//获取Payout Code列表 add by renyin
export const getPayoutCodeTable = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/payoutCodeList', params);
};
//获取Pending Create Payout Code列表 add by renyin
export const getPendingPayoutCodeTable = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/pendingPayoutCodeList', params);
};

//获取Dealer Payout Overview列表页的初始化查询区数据 add by renyin
export const getSearchResultForDealerPayoutOverview = (): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchGetMess('/ptapi/program/dealer/init/condition');
};
//获取CreatePayoutBatch编辑展示页接口 add by renyin
export const getCreatePayoutBatchDetail = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchGetMess('/ptapi/program/dealer/payoutLinkCreatePayout', params);
};
//获取CreatePayoutBatch页面添加按钮接口 add by renyin
export const callAddPayoutBatchButton = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/createPayoutAddButton', params);
};
//批量删除programs接口 add by renyin
export const batchDeletePrograms = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/deleteProgramCode', params);
};
//批量删除payoutCode接口 add by renyin
export const batchDeletePayoutCodes = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/deletePayoutCode', params);
};
//批量撤销按钮接口 add by renyin
export const batchWithDrawPrograms = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/withdraw', params);
};
//批量提交programs按钮接口 add by renyin
export const batchSubmitPrograms = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/managementSubmit', params);
};
//createPayoutSaveButton按钮接口 add by renyin
export const createPayoutSaveButton = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/createPayoutSaveButton', params);
};
//createPayoutSubmitButton按钮接口 add by renyin
export const createPayoutSubmitButton = (params = {}): Promise<any> => {
  //获取审批菜单跟踪列表数据
  return fetchPostPic('/ptapi/program/dealer/createPayoutSubmitButton', params);
};
// 获取Dealer Payout Overview列表
export const getDealerPayoutOverviewList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/payoutOverviewList', params);
};

// 获取F&L Payout Overview列表
export const getFlPayoutOverviewList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/flpayoutOverviewList', params);
};
//#endregion

//#region [Payment BU相关接口]
export const getDealerDataList = (buId: number): Promise<any> => {
  return fetchGet('/dapi/noAuth/dealer/dealerData', { params: { buId } });
};
export const getListDealerNumber = (params = {}): Promise<string> => {
  return fetchGet('/ptapi/detail/listDealerNumberDetail', params);
};
// 查询F&L列表接口
export const getBuFlPayoutOverviewList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/overview/buFlPayoutOverviewList', params);
};
// 初始化年月
export const getDealerPayoutCondition = (): Promise<any> => {
  return fetchGet('/ptapi/program/dealer/init/condition');
};
export const getRoundListByDate = (params = {}): Promise<any> => {
  return fetchGet('/ptapi/payoutRound/list', params);
};
// [OASIS-20680]
export const getRoundListByDateApprove = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/payoutRound/list/condition/approve', params);
};
// BBAC 获取round list的替换接口 需传入status列表
export const getRoundListByDateAndStatus = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/payoutRound/list/condition', params);
};
export const getCodeListByRound = (payoutRound: string | null): Promise<any> => {
  return fetchPost('/ptapi/overview/payout/code/list?payoutRound=' + payoutRound);
};
export const getInitCondition = (): Promise<any> => {
  return fetchGet('/ptapi/program/dealer/init/condition');
};
export const getPayoutRoundListApproved = (params = {}): Promise<any> => {
  return fetchPost('/apapi/payment/approval/getPayoutRoundListApproved', params);
};
export const getPayoutRoundListNotApproved = (params = {}): Promise<any> => {
  return fetchPost('/apapi/payment/approval/getPayoutRoundListNotApproved', params);
};
// 获取payoutRound的Year列表信息
export const getRoundYearList = (): Promise<any> => {
  return fetchGet('/ptapi/payoutRound/year/list');
};
// 根据年条件获取payout_round金融列表
export const getRoundFinanceList = (params = {}): Promise<any> => {
  return fetchGet('/ptapi/payoutRound/finance/list', params);
};
// 获取最新dealer的payoutRound信息
export const getDealerNewRound = (): Promise<any> => {
  return fetchGet('/ptapi/payoutRound/dealer/new');
};
// F&L 点击payoutCode下载
export const getPayoutCodeExcel = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/detail/excel', params);
};
// bu management 补充接口
// 通过BUs和entity查询可用审批流，返回buList
export const getListChainByEntityAndBus = (params = {}): Promise<any> => {
  return fetchPost('/apapi/payment/approval/listChainByEntityAndBus', params);
};
//#endregion

//#region [Payment Sales相关接口]
// Sales页面列表
export const getSalesOverviewList = (params = {}): Promise<any> => {
  console.log('params: ', params);

  return fetchPost('/ptapi/overview/salesOverviewList', params);
};

// Vehicle页面列表
export const getVehicleList = (params = {}): Promise<any> => {
  console.log('params: ', params);

  return fetchPost('/ptapi/vehicle/vendorList', params);
};

// Vendor页面列表
export const getVendorList = (params = {}): Promise<any> => {
  console.log('params: ', params);

  return fetchPost('/ptapi/vendor/vendorList', params);
};

//  Sales页面Release to Dealer按钮
export const paymentRelease2Dealer = (params = {}): Promise<any> => {
  console.log('params: ', params);

  return fetchPost('/ptapi/program/dealer/paymentRelease2Dealer', params);
};

// Sales页面下载按钮文件列表
export const getDownloadFileList = (entity: string): Promise<any> => {
  return fetchPost('/ptapi/export/excel/document?entity=' + entity);
};

// Sales页面上传按钮上传文件
export const getSalesUploadFile = (payoutRound: string, formData: FormData, entity: string): Promise<any> => {
  return fetchPostPic(`/ptapi/upload/sales`, formData, {
    params: { payoutRound, entity }
  });
};

// Sales页面上传按钮文件列表：根据source获取文件列表
export const getFileList = (payoutRound: string, source: string): Promise<any> => {
  return fetchGet(`/ptapi/file/list/${payoutRound}/${source}`);
};

// Sales页面上传按钮下载文件
export const getSalesDownloadFile = (fileId: string): Promise<any> => {
  return fetchGet(`/ptapi/download/document/${fileId}`);
};

export const getDealerBreakdownAttachmentFile = (fileId: string): Promise<any> => {
  return fetchGet(`/ptapi/download/document/watermark/${fileId}`);
};

// Sales页面上传按钮删除文件
export const getSalesDeleteFile = (fileId: string): Promise<any> => {
  return fetchGet(`/ptapi/delete/document/${fileId}`);
};

// Voucher页面列表
export const getVoucherList = (params = {}): Promise<any> => {
  console.log('params: ', params);

  return fetchPost('/ptapi/voucher/voucherList', params);
};

// Voucher页面文件上传（校验 + 保存）
export const importVoucher = (formData: FormData, params = {}): Promise<any> => {
  console.log('params: ', formData, params);

  return fetchPostPic('/ptapi/voucher/importFile', formData, { params });
};

// Voucher页面列表文件上传（校验）
export const uploadVoucher = (formData: FormData, params = {}): Promise<any> => {
  console.log('params: ', formData, params);

  return fetchPostPic('/ptapi/voucher/uploadFile', formData, { params });
};
// 经销商付款准备文件
export const dealerBreakdownList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/dealer/approve/dealerBreakdownOverview/list/dealer', params);
};
// Dealer Breakdown Overview（sales）页面下拉值初始化
export const salesBreakDownInitSelect = (entity: string): Promise<any> => {
  return fetchGet('/ptapi/dealer/approve/dealerBreakdownOverview/initSelect', {
    params: { entity }
  });
};
// Dealer Breakdown Overview（sales）页面payoutRound中文
export const getDealerApproveRoundNameCN = (): Promise<any> => {
  return fetchGet('/ptapi/payoutRound/dealer/approve/nameCN');
};

// Dealer Breakdown Overview（sales）页面列表
export const salesBreakDownList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/dealer/approve/dealerBreakdownOverview/list', params);
};

// Dealer Breakdown Overview（sales）文件列表
export const salesBreakDownFileList = (params = {}): Promise<any> => {
  return fetchGet('/ptapi/dealer/approve/dealerBreakdownOverview/fileNameList', { params });
};

// Dealer Breakdown Overview（sales）批量release
export const salesBreakDownBatchRelease = (params = {}): Promise<any> => {
  return fetchPostPic('/ptapi/dealer/approve/dealerBreakdownOverview/batchRelease', params);
};

// Dealer Breakdown Overview（sales）批量withdraw
export const salesBreakDownBatchWithdraw = (params = {}): Promise<any> => {
  return fetchPostPic('/ptapi/dealer/approve/dealerBreakdownOverview/batchWithdraw', params);
};

// Dealer Breakdown Overview（sales） Approve
export const salesBreakDownApprove = (params = {}) => {
  return fetchGetMess('/ptapi/dealer/approve/dealerBreakdownOverview/file/approve', { params });
};

// Dealer Breakdown Overview（sales） Reject
export const salesBreakDownReject = (params = {}) => {
  return fetchGetMess('/ptapi/dealer/approve/dealerBreakdownOverview/file/reject', { params });
};

// Dealer Breakdown Overview（sales） Reject comment话术列表
export const salesBreakDownCommentList = () => {
  return fetchGet('/ptapi/dealer/approve/dealerBreakdownOverview/rejectCommentList');
};

// Dealer Breakdown 详情 Breakdown列表
export const breakDownDetailList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/dealer/approve/dealerDetailList', {}, { params });
};

// Dealer Breakdown 详情 根据dealerDetailApproveId获取上传附件列表
export const breakDownDetailFileList = (dealerDetailApproveId: string): Promise<any> => {
  return fetchGet(`/ptapi/dealer/approve/file/list/${dealerDetailApproveId}`);
};

// Dealer Breakdown 详情 Approve
export const breakDownDetailApprove = (params = {}, formData: FormData) => {
  return fetchPostPic('/ptapi/dealer/approve/dealerUploadFile/submit/confirm', formData, { params });
};

// Dealer Breakdown 详情 Reject
export const breakDownDetailReject = (params = {}) => {
  return fetchGetMess('/ptapi/dealer/approve/dealerUploadFile/submit/reject', {
    params
  });
};

// Dealer Breakdown 车辆明细下载
export const paymentClaimExport = (params = {}) => {
  return axios.post('/ptapi/vehicle/paymentClaimExport', params, {
    responseType: 'blob'
  });
};

// DGRC Accounting 列表
export const getTrackingLogicList = (params = {}, currPage: number, pageSize: number): Promise<any> => {
  return fetchPost(`/ptapi/overview/trackingLogicList/${currPage}/${pageSize}`, params);
};
// export const fetchDgrcDownloadList = (params = {}): Promise<any> => {
//     return fetchPost('/ptapi/vendor/vendorListA3', params);
// }
export const fetchDgrcDownloadList = (params = {}): Promise<any> => {
  return fetchGet('/ptapi/file/list/source/upload_tracking_log');
  // return fetchGet('/ptapi/file/list/date/2021-03-04/upload_tracking_log');
};
// 删除
export const deleteDgrcUploadFile = (id: string): Promise<any> => {
  return fetchGet(`/ptapi/delete/document/${id}`);
};
//#endregion

//#region [Payment 移动端相关接口]
export const getPendingDetailAL = (payoutCode: any): Promise<any> => {
  return fetchPost(`/ptapi/detail/detailAL?payoutCode=${payoutCode}`);
};

export const getPendingDetailD = (id: any): Promise<any> => {
  return fetchPost('/ptapi/detail/detailD?id=' + id);
};
// 查询金融详情
export const getFinanceDetail = (payoutCodeId: string): Promise<any> => {
  return fetchPost('/ptapi/detail/finance?payoutCodeId=' + payoutCodeId);
};
// 查dealer
export const getDealerDetail = (payoutCodeId: string): Promise<any> => {
  return fetchPost('/ptapi/detail/dealer?payoutCodeId=' + payoutCodeId);
};
//#endregion
//#region [Dealer Setting相关接口]
// DealerSetting 条件查询
export const fetchListDealerSettingSIPayout = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/dealer/setting/listDealerSetting', params);
};
// DealerSetting 条件查询 替换接口
export const fetchListDealerSetting = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/dealer/setting/dealerStatusTracking', params);
};
// payment tracking -- dealer status tracking 接口替换
export const fetchListDealerSettingForTracking = (payoutRound: string, entity: string) => {
  return fetchPost(`/ptapi/dealer/setting/dealerStatusTracking`, {
    payoutRound,
    entity
  });
};
//#endregion
// 获取DCN模板设置
export const getDcnTemplate = (entity: string) => {
  return fetchGet(`/ptapi/dcn/template/${entity}`);
};
// 保存DCN模板设置
export const setDcnTemplate = (entity: string, data: unknown[]) => {
  return fetchPost('/ptapi/dcn/template/add', data, { params: { entity } });
};
// 上传DCN模板文件
export const uploadDcnTemplate = (params: object) => {
  return fetchPost('/ptapi/upload/dcnTemplate', params);
};

export const downloadPayoutCodeFile = (payoutCodes: string[] | string) => {
  const payoutCodeParam = Array.isArray(payoutCodes) ? payoutCodes : [payoutCodes];
  return axios.post(
    '/ptapi/program/dealer/excelByCode',
    {},
    {
      params: { payoutCode: payoutCodeParam.join(',') },
      responseType: 'blob'
    }
  );
};

export const downloadPendingDealerPayoutCodeFile = (payoutCode: string) => {
  return axios.get('/ptapi/export/pending/download/dealer', {
    params: { payoutCode },
    responseType: 'blob'
  });
};

export const downloadPendingAFCPayoutCodeFile = (payoutCode: string) => {
  return axios.get('/ptapi/export/pending/download/afc', {
    params: { payoutCode },
    responseType: 'blob'
  });
};

//#region Dealer Status Tracking收藏查询条件
// 增加条件数据
export const saveConditionDealerStatus = (params: object) => {
  return fetchPost('/ptapi/condition/saveConditionDealerStatus', params);
};

// 删除条件数据
export const deleteCondition = (id: string) => {
  return fetchGet(`/ptapi/condition/deleteCondition/${id}`);
};

// 查询条件数据
export const conditionAllDealerStatus = () => {
  return fetchGet('/ptapi/condition/conditionAllDealerStatus');
};
//#endregion

// 生成Round Summary Page version
export const addSummaryLog = (params: object) => {
  return fetchPost('/ptapi/summary/log/add', params);
};

// 获取Round Summary Page version列表
export const getSummaryLogList = (payoutRound: string, entity: string) => {
  return fetchGet(`/ptapi/summary/log/list/${payoutRound}/${entity}`);
};

// 下载Round Summary
export const downloadPayOutSummaryByVersion = (payoutRound: string, versionNameList: string[], entity: string) => {
  return axios.get('/ptapi/export/summary/version/download/dealer', {
    params: { payoutRound, versionNameList, entity },
    responseType: 'blob'
  });
};
//A3 tracking 附件列表接口
export const getUploadFileDataResut = (
  payoutRound: string,
  source = 'upload_tracking_log'
): Promise<AttachmentData[]> => {
  return fetchGetMess(`/ptapi/file/list/${payoutRound}/${source}`);
};
//A3 tracking 预览接口
export const getPreviewExcel = (payoutRound: string, dealerCode: string): Promise<AttachmentData[]> => {
  return fetchGet(`/ptapi/previewExcelFile/{payoutRound}/{dealerCode}`);
};
//A3 tracking 根据日期查询附件列表
export const getUploadFilesByDate = (dateStr: string, source = 'upload_tracking_log'): Promise<AttachmentData[]> => {
  return fetchGet(`/ptapi/file/list/date/${dateStr}/${source}`);
};
//A3 tracking 附件更新接口
export const updateAttachmentInfo = (fileId: string, payoutRound: string): Promise<AttachmentData[]> => {
  return fetchGetMess('/ptapi/dealer/approve/tracking/update?fileId=' + fileId + '&payoutRound=' + payoutRound);
};
//A3 tracking 附件删除接口----
export const deleteAttachmentInfo = (fileId: string): Promise<AttachmentData[]> => {
  return fetchGet(`/ptapi/delete/document/${fileId}`);
};
//A3 tracking 附件下载接口
export const downloadAttachmentInfo = (fileId: string): Promise<AttachmentData[]> => {
  return fetchGet(`/ptapi/download/document/${fileId}`);
};
//A3 tracking 附件下载接口（返回流）
export const downloadAttachment = (fileId: string): Promise<{ data: Blob }> => {
  return axios.get('/ptapi/downloadFile', {
    params: { fileId },
    responseType: 'blob'
  });
};
export const downloadAttachmentWatermark = (fileId: string): Promise<{ data: Blob }> => {
  return axios.get('/ptapi/downloadFile/watermark', {
    params: { fileId },
    responseType: 'blob'
  });
};
export const downloadOverviewPayoutCodeSheet = (payoutCodes: string[] | string) => {
  const payoutCodeParam = (Array.isArray(payoutCodes) ? payoutCodes : [payoutCodes]).join(',');
  return axios.get(`/ptapi/overview/clickPayoutCodeSheet/${payoutCodeParam}`, {
    responseType: 'blob'
  });
};
//#region审批流相关接口

//根据payoutCodeId获取审批流内容供前台展示图使用
export const getApprovalPackage = (payoutCodeId: string) => {
  return fetchGetMess(`/apapi/payment/approval/getApprovalPackage/${payoutCodeId}`);
};
//根据payoutCodeId获取审批流内容供前台展示图使用(created状态)
export const getApprovalPackageByCreated = (params: object) => {
  return fetchPostPic(`/ptapi/overview/fetchCreateStatusChain`, params);
};
//审批通过
export const chainApprove = (params: object) => {
  return fetchPostPic('/apapi/payment/approval/approvalPackageList', params);
};
//审批拒绝
export const chainReject = (params: object) => {
  return fetchPostPic('/apapi/payment/approval/rejectApproval', params);
};
//审批撤销
export const chainWithdraw = (params: object) => {
  return fetchPostPic('/apapi/payment/approval/withdrawApproval', params);
};
//#endregion

//#region sales management 需求变更接口
export const getPayoutRoundByYear = (year: string, entity: string) => {
  return fetchGet(`/ptapi/payout/Application/initPayoutRoundSelect?year=${year}&entity=${entity}`);
};
export const getDealerSalesStatus = (): Promise<any> => {
  return fetchGet('/ptapi/payout/Application/initStatusList');
};
export const getSalesList = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/payout/Application/salesOverviewList', params);
};
export const getSetList = (entity: string): Promise<any> => {
  return fetchGet(`/ptapi/payout/Application/initSetList?entity=${entity}`);
};
export const getPayoutCodeStatus = (params = {}): Promise<any> => {
  return fetchPost('/ptapi/payout/Application/payoutCodeStatus', params);
};
//#endregion
// 通过round和entity查询code
export const getPayoutCodeListByPayoutRoundAndEntity = (payoutRound: string, entity: string): Promise<any> => {
  return fetchPost(`/ptapi/overview/payout/entity/code/list?payoutRound=${payoutRound}&entity=${entity}`);
};
// Entity SI Payout Application next confirm后调用的接口
export const getDealerBreakdownOverview = (entity: string, payoutRound: string): Promise<any> => {
  return fetchGet(
    `/ptapi/dealer/approve/dealerBreakdownOverview/next/confirm?entity=${entity}&payoutRound=${payoutRound}`
  );
};
// 下载 sample check form
export const getProgramOfferTypeByPayoutRound = (payoutRound: string): Promise<any> => {
  return fetchGet(`/ptapi/program/dealer/getProgramOfferTypeByPayoutRound?payoutRound=${payoutRound}`);
};
// 校验Signed A3文件是否可下载
export const summaryLogCheck = (payoutRound: string, entity: string) => {
  return fetchGet(`/ptapi/summary/log/check/${payoutRound}/${entity}`, {
    params: {
      entity,
      payoutRound
    }
  });
};
// Payment Overview下载
export const downloadPaymentOverview = (payoutRound: string): Promise<AxiosResponse<Blob>> => {
  return axios.get(`/ptapi/export/downloadPaymentOverview`, {
    params: {
      payoutRound
    },
    responseType: 'blob'
  });
};
// Historical Red Invoice Notice Readiness payoutRound列表
export const redInvoiceNoticePayoutRoundList = (entity = 'MBCL'): Promise<any> => {
  return fetchPost('/ptapi/uploadRedInvoiceNoticeReceivedLog/payoutRoundList', {
    entity
  });
};
// Historical Red Invoice Notice Readiness 列表
export const redInvoiceNoticeList = (
  payoutRound: string,
  dealerCode: string,
  dealerName: string,
  pageNumber: number,
  pageSize: number,
  entity = 'MBCL'
): Promise<any> => {
  return fetchPost('/ptapi/uploadRedInvoiceNoticeReceivedLog/list', {
    payoutRound,
    dealerCode,
    dealerName,
    pageNumber,
    pageSize,
    entity
  });
};
// Historical Red Invoice Notice Readiness 导出
export const redInvoiceNoticeExport = (
  payoutRound: string,
  dealerCode: string,
  dealerName: string,
  entity = 'MBCL'
): Promise<any> => {
  return axios.post(
    '/ptapi/uploadRedInvoiceNoticeReceivedLog/export',
    {
      payoutRound,
      dealerCode,
      dealerName,
      entity
    },
    {
      responseType: 'blob'
    }
  );
};
